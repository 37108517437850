.footer{
    background-color: #2e343f;
  padding-top: 1rem;
  font-size: 1.4rem;
  height: 15rem;
  padding: 60px 0;
  position: relative; }

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.contact-area {
border-bottom: 1px solid #2e343f;
}

.contact-content p {
font-size: 15px;
margin: 30px 0 60px;
position: relative;
}

.contact-content p::after {
background: #f7f7f7;
bottom: -30px;
content: "";
height: 1px;
left: 50%;
position: absolute;
transform: translate(-50%);
width: 80%;
}

.contact-content h6 {
color: #8b9199;
font-size: 15px;
font-weight: 400;
margin-bottom: 10px;
}

.contact-content span {
color: #2e343f;
margin: 0 10px;
}

.contact-social {
margin-top: 3px;
}

.contact-social > ul {
display: inline-flex;
}

.contact-social ul li a {
border: 1px solid #8b9199;
color: #8b9199;
display: inline-block;
height: 40px;
margin: 0 10px;
padding: 1px;
transition: all 0.4s ease 0s;
width: 50px;
}
@media (max-width: 56.25em) {
    .contact-social ul li a {
        width: 36px;
        text-align: center;
    } }
    @media (max-width: 37.25em) {
      .contact-social ul li a {
          text-align: center;
        
      } }

.contact-social ul li a:hover {
border: 3px solid white;
color: #FAB702;
}

.contact-content img {
max-width: 210px;
}

footer {
margin-top: 4rem;
border-top: 1.3px solid grey;
background: #2e343f;
color: white;
margin-top: 1px;
}

footer p {
padding: 40px 0;
text-align: center;
font-size: 1.6rem;
}

footer img {
width: 44px;
margin-top: -2px;
}
.logo{
    width: 15rem;
    height: auto;
    
    

}
.logobox{
    text-align: center;
    margin-top: 4rem;
    margin-bottom: -5rem;
}

.hover-target{
    width: 4rem;

}


.formG:not(:last-child) {
    margin-bottom: 2rem; }
  
  
  
  .formI {
    color: black;
    font-size: 1.9rem;
    font-family: inherit;
    padding: 1.5rem 2rem;
    border-radius: 4px;
    background-color: rgba(114, 109, 109, 0.5);
    border: none;
    display: block;
    width: 75%;
    transition: all 3s; }
    .formI::-webkit-input-placeholder {
      color: #999; }
    .formI:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
      border-bottom: 3px solid #55c57a; }
    .formI:focus:invalid {
      border-bottom: 3px solid rgb(8, 8, 8); }
  
  .formI:placeholder-shown + .formL {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem); }
  
  .formL {
    font-size: 1.5rem;
    font-weight: 4rem;
    margin-left: 2rem;
    margin-top: .7rem;
    display: block; }
    .container {
        background-size: 100%;
        background-image: linear-gradient(105deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 50%, transparent 50%), url('../img3.jpg');
        width: 100%;
        margin-bottom: 1rem;
        border-radius: 3px;
        box-shadow: 0 1.5rem 4rem black, 0.2; }
        @media (max-width: 56.25em) {
          .book {
            padding: 10rem 0;
            background-image: linear-gradient(105deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 70%, transparent 70%), url(../img3.jpg); } }
        @media (max-width: 37.5em) {
          .book {
            padding: 10rem 0;
            background-image: linear-gradient(105deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%, transparent 100%), url(../img3.jpg); } }
        .bookF {
          width: 50%;
          padding: 4rem; }
          @media (max-width: 37.5em) {
            .bookF {
                padding: 2rem;
              width: 100%; } }

.contacthead{
    text-align: center;
    font-weight: 900;
    font-size: 4rem;
}
.btn1{
    font-size: 1.8rem;
}
.secBook{
    padding: 15rem 0;
}