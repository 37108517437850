.navigation {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: #fff;
    color: black;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
    z-index: 9000;
  }
  .brand-name {
    text-decoration: none;
    color: rgb(68, 138, 132);
    font-size: 5rem;
    margin-left: 1rem;
    font-weight: 900;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  .navigation-menu {
    margin-left: auto;
  }
  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  .navigation-menu li {

    list-style-type: none;
    margin: 0 1rem;
  }
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    font-size: 2rem;
  }
  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  .hamburger:hover {
    background-color: #2642af;
  }

  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
    .navigation-menu ul {
        display: none;
      }
        .navigation-menu ul {
          position: absolute;
          top: 60px;
          left: 0;
         flex-direction: column;
         width: 100%;
          height: calc(100vh - 77px);
          background-color: white;
          border-top: 1px solid black;
        }
        .navigation-menu li {
          text-align: center;
          margin: 0;
        }
        .navigation-menu li a {
          color: black;
          width: 100%;
          padding: 1.5rem 0;
        }
        .navigation-menu li:hover {
          background-color: #eee;
        }
      }
      @media screen and (max-width: 768px) {
        .navigation-menu ul {
          display: none;
        }
      }

      @media screen and (max-width: 768px) {
        .navigation-menu.expanded ul {
          display: block;
        }
      }