.headPage{
   font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
   font-size: 1.9rem;
}


.header{
    margin: 0 auto;
    display: flex;
    max-width: 114rem;
    position: relative;
    
    
 
}

h3{
font-size: 3.5rem;
font-family: Georgia, 'Times New Roman', Times, serif;
display: block;
text-transform: uppercase;
text-align: center;
font-weight: 700;
color: rgb(68, 138, 132);

 
}
.bc{
    background-color: #f7f7f7;
    padding-left: 2rem;
    padding-right: 2rem;

}
@media (max-width: 56.25em) {
     .headPage{
        margin-left: -99px;    }
}
@media (max-width: 56.25em) {
    h3 {
      font-size: 2.4rem;
        margin-top: 6rem;}
   
    }
.header1{
     width: calc((100% - 6rem) / 2);
     margin-right: 9rem;
     width: 25%;
     position: relative;
}

.product1{
    padding: 25rem 2rem;
    margin-top: -25vh;
    display: flex;

}
.featureBox{
    background-color: white;
    font-size: 1.8rem;
    text-align: center;
    padding: 2.5rem;
    border-radius: 20px;
    box-shadow: 0 1.5rem 4rem black ;
    transition: all 3s;


}
.FeatureH3{
    color: black;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.featureBox:hover {
    transform: translateY(-1.5rem) scale(1.03); }

.imgg{
    width: 50%;
    margin-top: -2rem;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    border-radius: 100px;
    position: absolute;
    transition: all .2s;
    z-index: 10;
    outline-offset: 2rem; } 
    @media (max-width: 56.25em){
     
        .imgg {
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.3);
            
        }
     }
      .imgg:hover {
        outline: 1.5rem solid rgb(68, 138, 132);
        transform: scale(1.05);
        box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
        z-index: 20;
        outline: 1.5rem solid rgb(68, 138, 132); }
      .imgg:hover .composition__Photo__Photo:not(:hover) {
        transform: scale(0.9); }
  

.imgg1{
    left: 0;
    top: -2rem;
    
}
@media (max-width: 56.25em) {
    .imgg1 {
      display: none; } }
.imgg2{
    right: 0rem;
    top: 2rem;
    
}
@media (max-width: 56.25em) {
    .imgg2 {
      display: none; } }
.imgg3{
   
    left: 20%;
    top: 7rem;
    
}
@media (max-width: 56.25em) {
    .imgg3 {
    left: -99px;
    top: -2rem;
     width: 15rem;
     margin-top: 20px;
     border-radius: 90%;
      transform: scale(1.1); } }
.row,
.p{
    
    width: calc((100% - 9rem) / 2); 
    margin-right: 9rem;
}
.btn,
.btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 2rem;
  border-radius: 10rem;
  transition: all 2s;
  color: black;
 

  border: none;
  cursor: pointer; }





  .row .col-1-of-2 {
    width: calc((100% - 6rem) / 2); 
    float: left;
    margin-right: 6rem;
}

.product1:not(:last-child) {
    margin-right: 9rem; }
    .product1 [class^="col-"] {
        margin-bottom: 6rem;
        margin-right: 6rem;
     }
    

    @media (max-width: 56.25em) {
        .product1 {
          display: block;
         
        
        } }
      .product1::after {
        content: "";
        display: table;
        clear: both; 
    }





   
    

